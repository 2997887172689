@import "src/styles/mixins";
@import "src/styles/variables";

.wrapper {
  position: relative;

  .icon {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.54);
    z-index: 5;
  }
}

.timePicker {
  @include formControl;
  padding: 9px 10px 9px 36px;
  font-size: 15px;
  line-height: 0.1;
  height: auto;
  color: rgba(0, 0, 0, 0.87);
  border: $input-border-width solid $input-border;
  border-radius: $input-border-radius;

  &:focus {
    outline: none;
    border-color: $input-border-focus;
  }
}

.popper {
  width: 100px;
  z-index: 6;

  :global(.react-datepicker__time-box) {
    width: 100px !important;
  }
  :global(.react-datepicker__time-container) {
    width: 100px !important;
  }
}
