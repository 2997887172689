.headerButtons {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;

  .icon {
    font-size: 16px;
    margin-left: 6px;
  }
}
