.app {
  display: flex;
  height: 100vh;
  width: 100vw;

  :global(.mdc-snackbar--leading) {
    margin: auto auto 25px 95px;
  }
}

// https://github.com/jamesmfriedman/rmwc/issues/276#issuecomment-407736788
:global(.mdc-text-field--focused:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid) .mdc-floating-label) {
  color: var(--mdc-theme-primary);
}

.snackbars {
  z-index: 100;

  :global(.mdc-snackbar__surface) {
    min-width: unset;
  }
}
