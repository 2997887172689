@import "src/styles/variables";

.form {
  margin: $side-modal-margin;
}

.typeSwitcher {
  display: grid;
  grid-template-columns: [title] 1fr [fields] 3.5fr;
  grid-column-gap: 1em;
  padding: 20px 0;
  margin: $side-modal-margin;

  .switcherLabel {
    grid-column: title;
    font-size: 15px;
    color: #4B5A73;
    margin-top: 9px;
  }

  .switcherFields {
    grid-column: fields;
    display: flex;
  }
}

.formSection {
  display: grid;
  grid-template-columns: [title] 1fr [fields] 3.5fr;
  grid-column-gap: 1em;
  padding: 20px 0;
  border-top: 1px solid #DDE2EB;

  .sectionTitle {
    grid-column: title;
    font-size: 15px;
    color: #4B5A73;
    margin-top: 9px;
  }

  .sectionFields {
    grid-column: fields;
    display: flex;
    flex-flow: column nowrap;
  }

  .textInput {
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: 20px;
  }
  :global(.mdc-text-field-helper-line) {
    grid-column: fields;
    margin: -1.2em 0 0.8em;
    * {
      color: #b00020 !important;
    }
  }
  .errorText {
    grid-column: fields;
    font-family: Roboto, sans-serif;
    font-size: 0.75rem;
    padding: 0.6em 16px 0;
    color: #b00020;
  }

  .cityStateZip {
    display: grid;
    grid-template-columns: 5fr 1.5fr 2fr;
    grid-column-gap: 10px;

    .textInput {
      margin: 0;
    }
  }

  .monthlyBilling {
    margin-top: 20px;
  }

  .sameAddress {
    margin: 11px 0 25px;
  }

  .notice {
    font-size: 12px;
    margin: 20px 0 5px;
    color: #4B5A73;
  }
}

.submitButton {
  margin-top: 30px;
  margin-bottom: 40px;
}
