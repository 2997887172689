@import "src/styles/variables";

.detailModal {
  display: flex;
  flex-flow: column nowrap;
  margin: $side-modal-margin;

  header {
    display: grid;
    grid-template-rows: auto auto;
    grid-row-gap: 12px;
    grid-template-columns: auto auto;
    grid-column-gap: 20px;
    justify-content: space-between;

    h3.title {
      font-size: 22px;
      color: $black;
    }

    .headerButtons {
      display: flex;
      justify-content: flex-end;
    }
    .btn {
      margin-left: 10px;
    }

    .billing {
      grid-column: 1 / span 2;
      font-size: 14px;
      line-height: 1.14;
      color: #4B5A73;

      &>.headerName {
        display: flex;
        align-items: center;

        span {
          font-weight: bold;
          line-height: 16px;
          margin-right: 10px
        }

        img {
          cursor: pointer;
          height: 16px;
          width: 16px
        }
      }

      .organization {
        display: block;
      }

      .contact {
        margin-right: 10px;
      }

      .leadSource {
        margin: 10px 0 6px 0;
      }

      &>div {
        margin-bottom: 6px;
      }
    }
  }

  .tabs {
    margin: 25px 0 17px;
    padding: 0;
  }
}
