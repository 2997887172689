@import "src/styles/variables";

.badge {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.17;
  padding: 4px 6px;
  border-radius: $input-border-radius;
  cursor: default;

  &.red {
    color: white;
    background-color: $secondary;
  }
  &.orange {
    color: white;
    background-color: $accurate-orange;
  }
  &.gray {
    color: $dark;
    background-color: #dde2eb;
  }
}
