@import "src/styles/variables";

.punches {

  table {
    font-size: 12px;
    width: 100%;
  }

  tr {
    border-bottom: 1px solid #d8d8d8;
    &:last-child {
      border-bottom: none
    }
  }

  th {
    font-weight: 500;
  }

  td {
    padding: 5px 0;
  }

  .punchTime {
    text-align: right
  }

  .punchEvent {
    font-weight: bold;
    text-align: left;
    &.start { color: $success }
    &.pause { color: $primary }
    &.stop { color: $secondary }
  }

  tfoot {
    th {
      border-top: 3px solid #d8d8d8;
      padding-top: 10px;

      &:last-child {
        text-align: right
      }
    }
  }

  .totalTimeForm {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    input {
      border: 1px solid $light;
      border-radius: $input-border-radius;
      box-shadow: none;
      outline: none;
      color: $dark;
      font-weight: 500;
      display: block;
      width: 100%;
      padding: 1px 4px;
      font-family: inherit;
      font-size: $font-size-sm;
      background-color: $body-bg;
      text-align: center;
      transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
      width: 65px;
    }

    span {
      margin-left: 3px
    }
  }

  .muted {
    color: $text-muted
  }

  .override {
    margin: 20px auto;

    button {
      margin: 10px auto;
    }
  }

  .inactive {
    color: $inactive;
  }
}
