@import "src/styles/variables";

.productsForm {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  .productRow {
    display: grid;
    grid-template-columns: 1fr 6fr 4fr 3fr 1fr;
    align-items: center;
    grid-gap: 5px;
    margin-top: 10px;
  }

  .select {
    grid-column: 2;
  }

  .addProductButton {
    margin-top: 10px;

    i.material-icons {
      font-size: 16px;
    }
  }

  .removeProductButton {
    color: $danger;
  }
}
