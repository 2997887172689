@import "src/styles/variables";

.status {
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;

  &.scheduled {
    color: $dark;
  }

  &.in_progress {
    color: $warning;
  }

  &.completed {
    color: $primary;
  }

  &.paid {
    color: $success;
  }

  &.cancelled {
    color: $danger;
  }

  &.problem {
    color: red;
  }
}
