@import "src/styles/variables";

.error {
  background-color: $danger;
  border: 1px solid transparent;
  border-radius: $input-border-radius;
  color: #fff;
  margin-bottom: 20px;
  padding: .75rem 1.25rem;
  position: relative;
}

