@import "src/styles/variables";

.modal {
  width: 500px;
}

.locationForm {
  .textInput {
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: 20px;
  }
  :global(.mdc-text-field-helper-line) {
    grid-column: fields;
    margin: -1.2em 0 0.8em;
    * {
      color: $danger !important;
    }
  }
  .errorText {
    grid-column: fields;
    font-family: Roboto, sans-serif;
    font-size: 0.75rem;
    padding: 0.6em 16px 0;
    color: $danger;
  }

  .cityStateZip {
    display: grid;
    grid-template-columns: 4fr 1fr 2fr;
    grid-column-gap: 10px;
    margin-bottom: 20px;

    .textInput {
      margin: 0;
    }
  }

  label.county {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    .select {
      flex: 1;
      margin-left: 20px;
    }
  }

  .checkbox {
    margin-top: 20px;
  }

  .btn {
    margin-top: 20px;
  }
}

.coordinates {
  color: $dark;
  font-size: 12px;
  margin-top: 15px;
  text-align: right;
  span {
    font-weight: bold;
    margin-right: 5px;
  }
}

.timeConstraints {
  margin: 20px 0;
  h5 {
    margin-bottom: 20px;
  }
}
