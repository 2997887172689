.constraintsTable {
  width: 100%;
  thead {
    th {
      color: rgba(0, 0, 0, 0.8);
      font-size: 13px;
      font-weight: 500;
      text-align: center;
      width: 20%;
    }
  }
  tbody {
    th,
    td {
      color: rgba(0, 0, 0, 0.8);
      font-size: 13px;
      font-weight: 500;
      text-align: center;
      padding: 8px 0;

      &>div {
        display: flex;
        justify-content: center;
      }
    }
  }
}