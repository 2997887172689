.serviceForm {
  display: grid;
  grid-template-columns: 1fr 2.5fr;
  align-items: center;
  grid-gap: 1em;

  label {
    grid-column: 1;
  }

  .formRow {
    grid-column: 1 / span 2;
  }

  .textInput,
  .textarea,
  .select {
    grid-column: 1 / span 2;
  }
  :global(.mdc-text-field-helper-line) {
    grid-column: 1 / span 2;
    margin: -0.85em 0 0;
    * {
      color: #b00020 !important;
    }
  }

  button[type="submit"] {
    grid-column: span 2;
    margin-top: 1em;
  }
}
