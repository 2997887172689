.form-item {
  align-items: unset;
  margin-bottom: 1rem;

  .with-button {
    display: flex;
    flex: 1;

    &>div {
      flex: 1;
      margin-right: 1rem
    }
  }
}
