@import "src/styles/variables";

$headerOffset: 36px;

.detailModal {
  display: flex;
  flex-flow: column nowrap;

  h5 {
    color: #80868c;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px;
    padding-bottom: 8px;
    flex: 1;
  }

  header {
    display: grid;
    grid-template-rows: auto auto;
    grid-row-gap: 12px;
    grid-template-columns: auto auto;
    grid-column-gap: 20px;
    justify-content: space-between;

    margin: $side-modal-margin;

    h3.title {
      font-size: 22px;
      color: $black;
    }

    .headerButtons {
      display: flex;
      justify-content: flex-end;
    }
  }

  .detailContent {
    font-size: 14px;
    line-height: 20px;
  }

  section {
    margin: $side-modal-margin;
    margin-bottom: 40px;

    &.serviceLocation,
    &.dateTime {
      display: grid;
      grid-template-rows: auto auto;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 5px;
    }

    p,
    li {
      line-height: 20px;
    }
  }

  .statusContainer {
    margin: 15px 30px 30px 40px;
  }
}

.locationName {
  font-weight: bold;
  color: $dark;
  margin-top: 5px;
}

.sectionHeader {
  display: flex;
  margin-left: -$headerOffset;
  min-height: 35px;

  i {
    color: rgba(0, 0, 0, 0.38);
    margin-right: 12px;
  }

  h4 {
    border-bottom: 1px solid #dde2eb;
    color: #80868c;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px;
    padding-bottom: 8px;
    flex: 1;

    &.offsetHeader {
      margin-left: $headerOffset;
    }
  }

  .sectionHeaderContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: -4px;
    padding-bottom: 8px;
    border-bottom: 1px solid #dde2eb;

    h4 {
      border: none;
      margin: 0;
      padding: 0;
    }

    .button {
      margin-left: 10px;
    }
  }
}

.strong {
  font-weight: 500;
  a {
    color: inherit;
    text-decoration: underline;
  }
}

.services {

  table {
    color: #000;
    font-size: 12px;
    width: 100%;
    border-collapse: initial;

    tbody {
      th,
      td {
        border-bottom: 1px solid #d8d8d8;
        padding: 10px 0;
        vertical-align: middle;
      }
      th.titleWrapper {
        display: flex;
        flex-flow: column nowrap;
      }
    }

    th {
      font-weight: 500;
    }

    .price {
      text-align: right
    }

    .salesSource {
      font-size: 10px;
      font-weight: normal;
    }

    tfoot {
      th {
        border-top: 3px solid #d8d8d8;
        padding-top: 10px;
      }
    }
  }

  .recurrence {
    span {
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 14px;
      text-transform: capitalize;
    }
    i {
      color: rgba(0,0,0,0.54);
      font-size: 18px;
      margin-right: 5px;
    }
  }
}

.products {
  display: flex;
  flex-flow: column nowrap;

  .productRow {
    display: grid;
    grid-template-columns: 3fr 3fr 1fr;
    grid-gap: 10px;
    align-items: center;
    font-size: 12px;
    line-height: 14px;
    padding: 10px 0;

    .productName {
      font-weight: 500;
    }

    &:not(:first-child) {
      border-top: 1px solid #d8d8d8;
    }

    & > span:nth-child(2) {
      justify-self: center;
    }
    & > span:nth-child(3) {
      justify-self: end;
    }
  }
}

.billingDetails {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;

  .invoiceNumber {
    font-family: monospace;
  }

  span {
    display: flex;
    align-items: center;
  }

  img {
    margin-right: 7px;
    height: 16px;
    width: 16px;
  }
}

.instructions,
.notes {
  p {
    line-height: 21px;
    white-space: pre-wrap;
  }
}

.inactive {
  color: $inactive;
}
