@import "src/styles/variables";

.overview {
  .bigMoneyContainer {
    display: flex;
    justify-content: space-between;

    .bigMoney {
      flex: 0 0 255px;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      padding: 24px 10px;
      color: white;
      background-color: $dark;
      border-radius: 3px;

      h1 {
        font-size: 44px;
      }

      h5 {
        font-size: 13px;
        font-weight: normal;
        margin-top: 4px;
      }
    }
  }

  section {
    margin-top: 50px;

    &:last-child {
      margin-bottom: 50px;
    }

    header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 10px;
      border-bottom: 2px solid #dde2eb;

      h4 {
        font-size: 18px;
        color: $black;
      }

      .btn {
        display: flex;
        align-items: center;
        font-size: 14px;

        b {
          font-size: 18px;
          margin-left: 18px;
        }
      }
    }

    p.notes {
      margin-top: 10px;
      font-size: 14px;
      line-height: 1.5;
      color: #4f4f4f;
      white-space: pre-line;
    }

    .detailRow {
      display: grid;
      grid-template-columns: 1fr auto 1fr;
      align-items: center;
      padding: 10px 0;
      font-size: 14px;
      line-height: 1.14;
      border-bottom: 1px solid #dde2eb;

      .detailTextStack {
        div:first-child {
          font-size: 14px;
          margin-bottom: 3px;
        }
        div:last-child {
          color: gray;
          font-size: 12px;
        }
      }

      .center {
        justify-self: center;
      }

      .end {
        grid-column: 3;
        justify-self: end;
        display: flex;
        align-items: center;

        i {
          margin-left: 20px;
          color: rgba(0, 0, 0, 0.54);
          cursor: pointer;
        }

        .badge {
          margin-left: 10px;
        }
      }

      .locationText {
        font-size: 13px;
        grid-column: 1 / span 2;

        .locationName {
          font-weight: bold;
          color: $dark;
          margin-bottom: 5px;
        }
      }

      .nextAppointment {
        color: #80868c;
        font-style: italic
      }
    }
  }

  .legacy {
    color: #80868c;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
  }
}
