@import "src/styles/variables";

.overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: 300ms ease background-color;
  z-index: 10;

  &.open {
    background-color: rgba(51, 51, 51, 0.5);
  }
}

.content {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  padding: 40px 0 20px 0;
  width: 640px;
  background-color: #fff;
  transition: 300ms ease transform;
  transform: translate3d(640px, 0, 0);
  z-index: 15;

  &.open {
    transform: translate3d(0, 0, 0);
  }

  &.locked {
    overflow: hidden;
  }

  svg.closeIcon {
    position: absolute;
    top: 48px;
    left: 20px;
    cursor: pointer;
  }

  h3.title {
    font-size: 22px;
    margin: $side-modal-margin;
    margin-bottom: 35px;
  }

  .contentWrapper {
    flex: 1 1 100%;
  }
}
