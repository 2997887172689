@import "src/styles/variables";

.statusBar {
  display: flex;
  min-height: 40px;

  .segment {
    background-color: #e8f0fc;
    color: #5b76a3;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    font-size: 14px;
    font-weight: 500;
    margin: 0 2px;
    position: relative;
    transition: background-color 0.2s ease;

    &:before,
    &:after {
      content: '';
      width: 0;
      height: 0;
      border-top: 21px solid transparent;
      border-bottom: 19px solid transparent;
      border-left: 17px solid #e8f0fc;
      position: absolute;
      right: -17px;
      top: 0;
      transition: border-color 0.2s ease;
      z-index: 2;
    }

    &:before {
      right: auto;
      left: 0;
      border-left: 17px solid #fff;
      z-index: 0;
    }

    &.complete {
      background-color: $success;
      color: #fff;

      &:after {
        border-left-color: $success;
      }
    }

    &:first-child {
      border-radius: 50px 0 0 50px;

      &:before {
        border: none
      }
    }

    &:last-child {
      border-radius: 0 50px 50px 0;

      &:after {
        border: none
      }
    }

    &.cancelled {
      border-radius: 50px;
    }

    &.problem {
      background-color: $secondary;
      border-radius: 50px;
      color: #fff;
    }
  }
}