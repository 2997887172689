@import "src/styles/variables";

.select {
  :global(.react-select__control) {
    border-color: rgba(0, 0, 0, 0.38);
  }
  :global(.react-select__indicator-separator) {
    display: none;
  }

  :global(.react-select__indicator) {
    margin-top: 0;
    padding: 0 0 4px 0;

    svg {
      width: 18px;
      height: 18px;
      margin: 5px 10px 0;
    }
  }

  :global(.react-select__menu) {
    text-align: left;
    z-index: 10;
  }
}

.select.invalid {
  :global(.react-select__control) {
    border-color: $danger;
  }
  :global(.react-select__placeholder) {
    color: $danger;
  }
}
