@import "src/styles/mixins";
@import "src/styles/variables";

.checkbox {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  visibility: visible;
  white-space: nowrap;

  &-wrapper {
    position: relative;
    display: block;
  }

  &-label {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.125rem;
    line-height: 1.5rem;
    position: relative;
    display: flex;
    cursor: pointer;
    padding-left: 1.625rem;
    min-height: 1.5rem;

    &:before {
      box-sizing: border-box;
      content: '';
      width: 1.125rem;
      height: 1.125rem;
      margin: 0.125rem;
      position: absolute;
      left: 0;
      background-color: #fff;
      border: 2px solid #8b9195;
      border-radius: 2px;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0.35rem;
      top: 0.3rem;
      width: 0.7rem;
      height: 0.4rem;
      background: none;
      border-left: 2px solid #fff;
      border-bottom: 2px solid #fff;
      transform: scale(0) rotate(-45deg);
      transform-origin: bottom right;
      margin-top: -0.1875rem;
    }
  }
  &.disabled + &-label {
    cursor: default;
  }
}

.checkbox:checked + .checkbox-label::before,
.checkbox:indeterminate + .checkbox-label::before,
.checkbox-label[data-contained-checkbox-state='true']::before,
.checkbox-label[data-contained-checkbox-state='mixed']::before {
  background-color: $primary;
  border-color: $primary;
  border-width: 1px;
}

.checkbox:checked + .checkbox-label::after,
.checkbox-label[data-contained-checkbox-state='true']::after {
  transform: scale(1) rotate(-45deg);
}

.checkbox.disabled + .checkbox-label:before {
  opacity: 0.5;
}
