@import "src/styles/variables";

header.header {
  display: flex;
  align-items: center;
  margin-top: -5px;
  padding-bottom: 25px;

  h3 {
    font-size: 22px;
    margin: $side-modal-margin;
    margin-right: 29px;
  }

  .radio:not(:first-of-type) {
    margin-left: 15px;
  }
}
