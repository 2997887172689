.input {
  background-color: #fff !important; 
  width: 100%;
}

.instructions {
  text-align: center;
  p {
    font-size: 15px;
    margin-bottom: 20px;
  }
}