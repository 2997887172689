@import "src/styles/variables";

.appointmentForm {
  section {
    display: grid;
    grid-template-columns: [title] 1fr [fields] 3.5fr;
    grid-column-gap: 1em;
    margin: $side-modal-margin;
    padding: 20px 0;
    border-top: 1px solid #DDE2EB;

    .sectionTitle {
      grid-column: title;
      font-size: 15px;
      color: #4B5A73;
      margin-top: 9px;
    }

    .sectionFields {
      grid-column: fields;
      display: flex;
      flex-flow: column nowrap;
    }

    .locationOption {
      p {
        font-weight: 500;
        margin-bottom: 3px;
      }
    }

    .btn {
      align-self: flex-start;
      padding: 5px 12px;
      margin-bottom: 20px;
    }

    .select:not(:last-child),
    .textInput:not(:last-child),
    .dateTimeInputs:not(:last-child) {
      margin-bottom: 20px;
    }

    .locationSelect {
      .react-select__value-container {
        padding-bottom: 12px;
        padding-top: 12px;
      }
    }

    .services {
      margin-bottom: 30px;
    }

    .serviceRow {
      background-color: #e8f0fc;
      font-size: 12px;
      display: grid;
      grid-template-columns: 9fr 1fr 2fr 1fr;
      align-items: center;
      padding: 10px;

      .serviceName {
        color: #112240;
        font-weight: 500;
        padding-right: 15px;
        text-align: left;
      }
      .servicePrice {
        text-align: center;

        input {
          background-color: #fff;
          border: 1px solid #80868C !important;
          border-radius: 4px;
          font-size: 12px;
          text-align: right;
          height: 25px;
          padding: 3px 6px;
          width: 100%;
        }
      }
      .serviceRemove {
        text-align: center;
        b {
          background-color: $secondary;
          border-radius: 50%;
          color: #fff;
          cursor: pointer;
          display: inline-block;
          margin: 0 auto;
          height: 16px;
          width: 16px;
        }
      }

      .select {
        margin: 0;
      }
    }

    .serviceTotal {
      font-size: 12px;
      font-weight: 500;
      display: grid;
      grid-template-columns: 9fr 1fr 2fr 1fr;
      align-items: center;
      margin-bottom: 1px;
      padding: 12px 10px;

      .label {
        color: #112240;
        grid-column: 1/3
      }

      .value {
        text-align: right;
      }
    }

    .salesSource {
      display: flex;
      align-items: center;
      color: $black;
      background-color: lighten(#e8f0fc, 2%);
      font-size: 12px;
      font-weight: 500;
      padding: 8px 10px;
      margin-bottom: 1px;

      select {
        flex: 1 1 auto;
        margin-left: 10px;
      }
    }

    .textInput {
      display: flex;
      flex-flow: column nowrap;

      &.short {
        width: 12em;
      }
    }
    :global(.mdc-text-field-helper-line) {
      grid-column: fields;
      margin: -1.2em 0 0.8em;
      * {
        color: #b00020 !important;
      }
    }
    .errorText {
      grid-column: fields;
      font-family: Roboto, sans-serif;
      font-size: 0.75rem;
      margin: -22px 0 0;
      padding: 0.6em 16px 0 16px;
      color: #b00020;
    }
    .errorText:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .timeConstraints {
    p {
      font-size: 13px;
      font-weight: 500;
      margin-bottom: 15px;
    }
  }

  .twoColumns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1em;
    margin-bottom: 20px;
    align-items: center;
  }

  .action {
    margin: $side-modal-margin;
  }

  .submitButton {
    margin-bottom: 40px;
    margin-top: 20px;
  }

  .cancelApppointment {
    margin-bottom: 20px;

    button {
      color: $secondary;
      margin: 0 auto
    }
  }
}
