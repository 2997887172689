@import "src/styles/variables";

.organizationForm {

  .textInput {
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: 20px;
  }

  .cityStateZip {
    display: grid;
    grid-template-columns: 5fr 1.5fr 2fr;
    grid-column-gap: 10px;
    margin-bottom: 20px;

    .textInput {
      margin: 0;
    }
  }

  :global(.mdc-text-field-helper-line) {
    grid-column: fields;
    margin: -1.2em 0 0.8em;
    * {
      color: $danger !important;
    }
  }
  .errorText {
    grid-column: fields;
    font-family: Roboto, sans-serif;
    font-size: 0.75rem;
    padding: 0.6em 16px 0;
    color: $danger;
  }

  button[type="submit"] {
    margin-top: 1em;
  }
}