.resourceHeader {
  font-size: 13px;
  padding: 12px;
  text-align: left;

  strong {
    display: block;
    font-weight: bold;
    // margin-bottom: 5px;
  }

  p {
    font-weight: 500;
  }
}