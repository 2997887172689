.reports {
  display: flex;
  height: 100%;

  h3 {
    color: #5F6368;
    font-size: 14px;
    margin-bottom: 15px;
    text-transform: uppercase;
  }

  .leftColumnWrapper {
    flex: 1 0 auto;
    grid-column: left;
    display: flex;
    flex-flow: column nowrap;
    margin-right: 30px;

    .timeframe {
      flex: 0 0 auto;
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      margin-top: 45px;
    }

    .countyHeader {
      margin-top: 30px;
    }
    .counties {
      flex: 1 1 auto;
      display: flex;
      flex-flow: column nowrap;
      overflow-y: scroll;

      .radio {
        font-size: 14px;
        color: #555555;
        margin: -10px 0 0 -10px; // hey it works
      }
    }

    .exportButton {
      flex: 0 0 auto;
      margin: 25px 0 15px;

      & > div {
        flex: 1 0 auto;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
      }

      i {
        color: white;
        font-size: 18px;
      }
    }
  }

  .reportTable {
    flex: 8 1 85%;
  }
}
