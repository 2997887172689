@import "src/styles/variables";

.constraintsTable {
  background-color: #fffae9;
  margin-bottom: 20px;
  width: 100%;
  thead {
    tr { border-bottom: 2px solid $border-color }
    th {
      color: rgba(0, 0, 0, 0.8);
      font-size: 13px;
      font-weight: 500;
      padding: 8px 6px;
      text-align: center;
      width: 20%;
    }
  }
  tbody {
    tr:first-child { border-bottom: 1px solid $border-color }
    th {
      color: rgba(0, 0, 0, 0.8);
      font-size: 13px;
      font-weight: 500;
      padding: 8px;
      text-align: center;
    }

    td {
      font-size: 18px;
      font-weight: bold; 
      padding: 8px 0;
      text-align: center;
      &.available {
        color: $success;
      }
    }
  }
}