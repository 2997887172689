.modal {
  width: 500px;
}

.contactForm {
  .textInput {
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: 20px;
  }
  :global(.mdc-text-field-helper-line) {
    grid-column: fields;
    margin: -1.2em 0 0.8em;
    * {
      color: #b00020 !important;
    }
  }

  .checkbox {
    margin-top: 20px;
  }

  .btn {
    margin-top: 20px;
  }
}
