@import "src/styles/mixins";
@import "src/styles/variables";

.btn {
  @include button;
  &:disabled {
    opacity: .65;
    cursor: default;
  }

  font-weight: bold;

  &--primary {
    color: #fff;
    background-color: $primary;
    border-color: $primary;

    &:focus {
      outline: none;
      background-color: darken($primary, 6%);
      border-color: darken($primary, 6%);
    }

    &:hover,
    &:active {
      background-color: darken($primary, 6%);
      border-color: darken($primary, 6%);
    }
  }

  &--gray {
    color: $dark;
    background-color: $light;
    border-color: $light;

    &:focus {
      outline: none;
      background-color: darken($light, 6%);
      border-color: darken($light, 6%);
    }

    &:hover,
    &:active {
      background-color: darken($light, 6%);
      border-color: darken($light, 6%);
    }
  }

  &--block {
    width: 100%;
  }

  &--success {
    color: #fff;
    background-color: $success;
    border-color: $success;

    &:focus {
      outline: none;
      background-color: darken($success, 6%);
      border-color: darken($success, 6%);
    }

    &:hover,
    &:active {
      background-color: darken($success, 6%);
      border-color: darken($success, 6%);
    }
  }

  &--danger {
    color: #fff;
    background-color: $danger;
    border-color: $danger;

    &:focus {
      outline: none;
      background-color: darken($danger, 6%);
      border-color: darken($danger, 6%);
    }

    &:hover,
    &:active {
      background-color: darken($danger, 6%);
      border-color: darken($danger, 6%);
    }
  }

  &--sm {
    font-size: $font-size-sm;
    line-height: 1.2;
    height: auto;
    padding: 8px 12px;
  }

  &--large {
    font-size: $font-size-md;
    height: auto;
    padding: 0.75rem;
  }

  &--link {
    background: transparent;
    padding: 0;
    color: $primary
  }

  svg {
    fill: #fff;
    margin-left: 10px;
  }
}
