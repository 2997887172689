$primary:               #3670D0;
$secondary:             #d82f22;

$info:                  blue;
$success:               #0e9D58;
$warning:               orange;
$danger:                #b00020;

$light:                 #d3dceb;
$dark:                  #4b5a73;
$black:				          #102240;
$white:				          #ffffff;

$gray:                  #f1f3f4;
$inactive:              #B7BEC1;

$body-bg:       	      #ffffff;

$border-color:	  	    rgba(135, 150, 165, 0.15);
$border-color-lt:	      rgba(135, 150, 165, 0.075);
$box-shadow:		        0 1px 3px rgba(0,0,0,0.05), 0 -1px 0px rgba(0,0,0,0.015);
$box-shadows: 		      0 5px 30px rgba(0,0,0,0.1);

$font-family-base:			-apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$font-family-serif:     Georgia, "Times New Roman", Times, serif;

$font-size-base:			  0.875rem;
$line-height-base:			1.25/0.875;

$font-size-lg:          2.5rem;
$font-size-md:          1rem;
$font-size-sm:          0.75rem;

$text-color:            #333333;
$text-muted:            #999999;

$input-border:          #80868C;
$input-border-width:    1px;
$input-border-focus:    $primary;
$input-border-radius:   3px;

$side-modal-margin:     0 40px 0 50px;

$accurate-orange:       #F7792A;
