@import "src/styles/variables";

.status {
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;

  &.paid {
    color: $success
  }

  &.open {
    color: $danger
  }
}