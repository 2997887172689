@import "src/styles/variables";

.invoices {
  border-top: 1px solid #f0f0f0;
  margin-bottom: 50px;

  .invoiceRow {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    justify-content: center;
    padding: 12px 0;
    border-bottom: 1px solid #f0f0f0;
    font-size: 14px;
    line-height: 1;
    cursor: pointer;

    .invoiceNumber {
      font-family: monospace;
    }
    .invoiceTotal {
      justify-self: end;
    }

    & > span:first-child {
      justify-self: start;
    }
    & > span:last-child {
      justify-self: end;
    }

    .status {
      font-size: 12px;
      line-height: 0;
    }
  }

  .noApptsMessage {
    color: $inactive;
    padding: 20px;
    text-align: center;
  }
}
