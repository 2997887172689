.rt-td.lastLogin {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.rt-td.role {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.discarded {
  .rt-td.role,
  .rt-td.lastLogin {
    text-decoration: none !important
  }
  .rt-td.role {
    .content {
      text-decoration: line-through
    }
  }
}

