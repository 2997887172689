.dateTime {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #80868c;
  font-size: 12px;

  .date {
    margin-right: 15px;
  }

  .recurrence {
    height: 15px;
    i {
      color: rgba(0,0,0,0.54);
      font-size: 18px;
      padding-right: 15px;
    }
  }

  .timeHyphen {
    margin: 0 5px;
  }
}
.accountName {
  font-weight: 500
}
.technician {
  height: 100%;
  span {
    border: 1px solid #fff;
    border-radius: 2px;
    display: block;
    padding: 7px;
  }
}
.reason {
  text-align: right
}
