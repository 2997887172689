@import "src/styles/variables";

.sidebar {
  box-shadow: 5px 0 10px 0 rgba(135,135,135,0.70);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  padding: 30px 0;
  position: fixed;
  bottom: 0;
  top: 0;
  width: 70px;
  z-index: 10;

  .menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  ul {
    text-align: center;

    .icon {
      color: #5F6368;
      font-size: 22px;
    }

    li {
      margin-bottom: 10px;

      a {
        display: block;
        padding: 13px 14px;
      }

      &.active {
        background-color: $secondary;

        .icon {
          color: white;
        }
      }
    }
  }

  img {
    margin-bottom: 23px;
    width: 35px
  }
}
