@import "src/styles/variables";

.ReactTable {
  border: none;
  height: 100%;

  .rt-table {
    color: #4b5a73;
    font-size: 14px;
    height: 100%;
  }
  .rt-thead {
    .rt-tr {
      text-align: left;
    }

    .rt-th {
      color: $primary;
      font-size: 12px;
      font-weight: bold;
      padding: 15px 10px;
      text-transform: uppercase;

      &.-sort-asc,
      &.-sort-desc {
        box-shadow: none;

        .rt-resizable-header-content {
          span {
            background-image: url(../assets/sort-asc.svg);
            background-repeat: no-repeat;
            background-position: center right;
            padding-right: 20px
          }
        }
      }

      &.-sort-desc {
        .rt-resizable-header-content {
          span {
            background-image: url(../assets/sort-desc.svg);
          }
        }
      }

      &:focus {
        outline: none
      }
    }

    .header {
      box-shadow: none;
    }
  }
  .rt-tbody {
    background-color: #ffffff;
    border-radius: 4px;
    height: 100%;
    overflow-y: auto;

    .rt-tr-group {
      flex: 0 0 auto;
      border-bottom: 0;
      border-top: 1px solid #f0f0f0;

       &:hover {
        background-color: #e8f0fc;
        border-top: 1px solid #c3c9d4;
        border-bottom: 1px solid #c3c9d4;

        & + .rt-tr-group {
          border-top: 0
        }
      }
    }

    .rt-td {
      cursor: pointer;
      padding: 10px;

      &.technicianCell {
        padding: 2px;
      }
    }

    .discarded {
      .rt-td {
        color: #999;
        text-decoration: line-through;
      }
    }
  }
}
