.monthPicker-wrapper {
  position: relative;

  i.material-icons {
    position: absolute;
    top: 7px;
    right: 11px;
    font-size: 20px;
    color: #B7BEC1;
    cursor: default;
    pointer-events: none;
  }
}

.monthPicker {
  position: relative;
  padding: 8px 11px;
  border: none;
  border-radius: 3px;
  color: #414549;
  font-size: 14px;
  border: 1px solid transparent;
  outline: none;

  &:focus {
    border-color: 1px solid #3670D0;
  }
}

.react-datepicker__month-text {
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  height: 3em;
}

.react-datepicker-popper {
  min-width: 240px;
}

.react-datepicker-year-header {
  padding-bottom: 8px;
}
