.optimizeModal {
  width: 660px;

  .modalText {
    font-size: 14px;
    line-height: 1.5;
  }

  .list {
    list-style: disc outside;
    margin: 15px 0 0 20px;
  }

  .optimizeButton {
    margin-top: 30px;

    &.optimizing {
      opacity: 1;
      border-color: transparent;
      background: none;
      background-image: repeating-linear-gradient(
        -70deg,
        #7fa4e2,
        #7fa4e2 13px,
        #6994dc 13px,
        #6994dc 16px
      );
      background-size: 200% 200%;
      animation: stripey 30s linear infinite;
    }
  }

}

@keyframes stripey {
  100% {
    background-position: 100% 100%;
  }
}
