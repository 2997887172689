@import "src/styles/variables";

.productForm {
  display: grid;
  grid-template-columns: 1fr 2.5fr auto;
  align-items: center;
  grid-gap: 1em;

  .status {
    grid-column: span 3;
  }

  label {
    grid-column: 1;
  }

  .textInput {
    grid-column: 1 / span 2;
  }
  :global(.mdc-text-field-helper-line) {
    grid-column: 1 / span 2;
    margin: -0.85em 0 0;
    * {
      color: $danger !important;
    }
  }

  button[type="submit"] {
    grid-column: span 3;
    margin-top: 1em;
  }

  .serviceSelect {
    grid-column: 2;
  }

  .addRemoveButton {
    grid-column: 3;
    cursor: pointer;
  }

  .deactivateButton {
    margin-top: 1em;
    color: $secondary;
    grid-column: 1 / span 2;
  }
}
