.rt-td.category {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.discarded {
  .rt-td.category {
    text-decoration: none !important;
    .content {
      text-decoration: line-through
    }
  }
}