.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 40px;

  h1 {
    font-size: 36px;
    font-weight: normal;
  }
}