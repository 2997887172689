@mixin button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: $font-size-base;
  line-height: $line-height-base;
  height: 34px;
  border-radius: $input-border-radius;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

@mixin formControl {
  border: 1px solid $border-color;
  border-radius: $input-border-radius;
  box-shadow: none;
  color: $dark;
  display: block;
  width: 100%;
  height: calc(2rem + 2px);
  padding: .375rem .75rem;
  font-family: inherit;
  font-size: $font-size-base;
  line-height: $line-height-base;
  background-color: $body-bg;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@mixin bubble {
  border-radius: 3px;
  box-shadow: 0 1px 0 0 #e3e6ed;
  flex: 0 0 50%;
  position: relative;
}

@mixin bubbleArrow {
  position: absolute;
  top: 20px;
  content: "";
  width: 30px;
  height: 30px;
  z-index: 0;
}
