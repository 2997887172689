.modal {
  width: 500px;
}

.billingForm {
  .label {
    display: block;
    font-size: 12px;
    color: rgba(0,0,0,0.6);
    margin-bottom: 5px;
  }

  .selectInput {
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: 10px;
  }

  .textInput {
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: 20px;
  }

  :global(.mdc-text-field-helper-line) {
    grid-column: fields;
    margin: -1.2em 0 0.8em;
    * {
      color: #b00020 !important;
    }
  }
  .errorText {
    grid-column: fields;
    font-family: Roboto, sans-serif;
    font-size: 0.75rem;
    padding: 0.6em 16px 0;
    color: #b00020;
  }

  .contactSelect {
    margin-bottom: 20px;
  }

  .cityStateZip {
    display: grid;
    grid-template-columns: 5fr 1.5fr 2fr;
    grid-column-gap: 10px;

    .textInput {
      margin: 0;
    }
  }

  .monthlyBilling {
    margin-top: 18px;
  }

  .leadSource {
    display: flex;
    align-items: center;
    justify-content: stretch;
    margin-top: 18px;

    .leadSourceSelect {
      flex: 1;
      margin-left: 10px;
    }
  }

  .btn {
    margin-top: 20px;
  }
}
