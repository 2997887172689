@import "src/styles/variables";

.commissionSourceForm {
  display: grid;
  grid-template-columns: 1fr 2.5fr;
  align-items: center;
  grid-gap: 1em;

  label {
    grid-column: 1;
  }

  .textInput {
    grid-column: 2;
  }
  :global(.mdc-text-field-helper-line) {
    grid-column: 2;
    margin: -0.85em 0 0;
    * {
      color: $danger !important;
    }
  }

  .errorText {
    grid-column: 2;
    margin: -0.85em 0 0;
    padding: 0 16px;
    color: $danger;
    font-size: 0.75rem;
  }

  button[type="submit"] {
    grid-column: span 2;
    margin-top: 1em;
  }

  .checkbox {
    margin-top: 1em;
    grid-column: 1 / span 2;
  }

  .deactivateButton {
    color: $secondary;
    grid-column: 1 / span 2;
  }
}
