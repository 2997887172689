@import "src/styles/mixins";
@import "src/styles/variables";

.reports {
  display: flex;
  height: 100%;

  h3 {
    color: #5F6368;
    font-size: 14px;
    margin-bottom: 15px;
    text-transform: uppercase;
  }

  .datePickerWrapper {
    align-self: stretch;
    display: grid;
    grid-template-columns: 50px auto;
    align-items: center;
    position: relative;
    font-size: 14px;
    color: #555555;

    &:first-of-type {
      margin-bottom: 5px;
    }

    .datePicker {
      @include formControl;
      &:focus {
        outline: none;
        border-color: $primary;
      }
    }

    .datePickerIcon {
      position: absolute;
      top: 7px;
      right: 11px;
      font-size: 20px;
      color: #B7BEC1;
      cursor: default;
      pointer-events: none;
    }

    :global(.react-datepicker__input-container) {
      width: 100%;
    }
  }

  .leftColumnWrapper {
    flex: 0 0 auto;
    grid-column: left;
    display: flex;
    flex-flow: column nowrap;
    margin-right: 30px;

    .scrollWrapper {
      flex: 1 1 auto;
      overflow-y: auto;
    }

    .timeframe {
      flex: 0 0 auto;
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      margin: 45px 0 30px;
    }

    .typeButtons {
      flex: 0 1 auto;
      display: flex;
      flex-flow: column nowrap;
      margin-bottom: 30px;

      .typeButton {
        font-size: 14px;
        color: #555555;
        margin: -10px 0 0 -10px;
        text-transform: capitalize;
      }
    }

    .exportButton {
      flex: 0 0 auto;
      margin: 25px 0 15px;

      & > div {
        flex: 1 0 auto;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
      }

      i {
        color: white;
        font-size: 18px;
      }
    }
  }

  .reportTable {
    flex: 4 1 auto;
    width: 100%;
    overflow-x: auto;
  }
}
