@import "styles/reset";
@import "styles/variables";

body {
  background-color: $body-bg;
  color: $text-color;
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: $font-family-base;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

strong {
  font-weight: bold;
}

a {
  color: $primary
}
