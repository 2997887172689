.row {
  display: grid;
  grid-template-columns: 4fr 3fr 1fr 1fr;
  grid-column-gap: 5px;
  align-items: center;
  padding: 10px 0;
  font-size: 14px;
  line-height: 1.14;
  border-bottom: 1px solid #dde2eb;

  .service {
    color: #112240;
    line-height: 1.4;
  }

  .action {
    justify-self: end;
    display: flex;
    align-items: center;

    i {
      margin-left: 20px;
      color: rgba(0, 0, 0, 0.54);
      cursor: pointer;
    }
  }

  .nextAppointment {
    color: #80868c;
    font-style: italic
  }
}