.calendarWrapper {
  display: flex;
  height: 100%;

  &.isOptimizing {
    .rbc-event {
      transition: top 1s ease-in-out;
    }
  }
}

.rbc-calendar {
  flex: 1;
  overflow: auto;

  .rbc-toolbar {
    margin-bottom: 20px;

    &-label {
      color: #102240;
      font-size: 18px;
      font-weight: 500;
    }
  }

  .rbc-btn-group {
    button {
      border: 0;
      font-size: 14px;
      line-height: 18px;
      font-weight: 500;
    }

    &:first-child {
      button {

        &:first-child {
          background-color: #d3dceb;
          border-radius: 3px;
          color: #102240;
          margin-right: 10px;
          padding: 7px 10px;
        }
      }
    }

    &:last-child {
      button {
        background-color: #d3dceb;
        padding: 7px 16px;
        text-transform: uppercase;

        &:first-child {
          border-radius: 100px 0 0 100px;
        }

        &:last-child {
          border-radius: 0 100px 100px 0;
        }

        &.rbc-active {
          background-color: #d82f22;
          border: 0;
          box-shadow: none;
          color: #fff;
        }
      }
    }
  }

  .rbc-month-view {
    height: auto;
  }

  .rbc-time-view {
    border: 1px solid #dde2eb;
    border-radius: 4px 4px 0 0;
    background-color: #fff;

    &-resources {
      .rbc-time-gutter,
      .rbc-time-header-gutter {
        border-right: 1px solid #dde2eb;
      }
    }
  }

  .rbc-header {
    border-bottom: 0;
    font-size: 12px;
    font-weight: 500;
  }

  .rbc-time-header {
    &.rbc-overflowing {
      border-right: 0
    }
  }

  .rbc-time-header-content {
    justify-content: center;
    
    &> .rbc-row.rbc-row-resource {
      border-bottom: 0;
    }
  }

  .rbc-allday-cell { display: none }

  .rbc-time-gutter {
    .rbc-time-slot {
      color: #4b5a73;
      font-size: 12px;
      line-height: 19px;
      padding: 5px 6px;
      text-align: right;
    }
  }

  .rbc-timeslot-group {
    border-bottom: 1px solid #dde2eb;
    height: 65px;
  }

  .rbc-today {
    background-color: #fff;
  }

  .rbc-time-content {
    border-top: 2px solid #dde2eb;

    &> * + * > * {
      border-left: 1px solid #dde2eb;
    }
  }

  .rbc-day-slot {
    .rbc-event-content {
      line-height: initial
    }
  }

  .rbc-date-cell {
    font-size: 12px;
  }

  .rbc-event {
    background-color: inherit;
    border: 1px dashed rgba(16,34,64,0.2);
    border-radius: 2px;
    color: #102240;
    font-size: 12px;
    font-weight: bold;

    &-label {
      color: #102240;
      font-size: 12px;
      font-weight: normal;
    }
  }

  .eventWrapper {
    &.block {
      .rbc-event {
        background-image: linear-gradient(135deg, #cccccc 45.45%, #999999 45.45%, #999999 50%, #cccccc 50%, #cccccc 95.45%, #999999 95.45%, #999999 100%);
        background-size: 15.56px 15.56px;
        border: 1px solid rgba(16,34,64,0.33);
      }
    }
    &.fixed {
      .rbc-event {
        border: 1px solid rgba(16,34,64,0.33);
      }
    }
  }
}
