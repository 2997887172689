@import "src/styles/variables";

.userForm {
  display: grid;
  grid-template-columns: 1fr 2.5fr;
  align-items: center;
  grid-gap: 1em;

  label {
    grid-column: 1;
  }

  .formRow,
  .textInput,
  .technician {
    grid-column: 1 / span 2;
    width: 100%;

    h5 {
      margin-bottom: 15px;
      margin-top: 10px;
    }
  }

  .commission {
    display: flex;
    align-items: center;
    width: 50%;

    input {
      flex: 1;
    }
    span {
      font-size: 20px;
      margin-left: 10px;
    }
  }

  :global(.mdc-text-field-helper-line) {
    grid-column: 1 / span 2;
    margin: -0.85em 0 0;
    * {
      color: $danger !important;
    }
  }

  button[type="submit"] {
    grid-column: span 2;
    margin-top: 1em;
  }

  .deactivateButton {
    margin-top: 1em;
    color: $secondary;
    grid-column: 1 / span 2;
  }
}
