// Leading icon
.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-text-field__icon {
  left: 10px;
  top: 10px;
  font-size: 18px;
}

// Trailing icon
.mdc-text-field--with-trailing-icon.mdc-text-field--outlined .mdc-text-field__icon {
  right: 10px;
  top: 10px;
  font-size: 18px;
}

// Inline, non-floating labels in general
.mdc-floating-label:not(.mdc-floating-label--float-above) {
  top: 10px;
  left: 0;
  font-size: 15px;
}

// Inline, non-floating labels for inputs with a leading icon
.mdc-text-field--with-leading-icon .mdc-floating-label:not(.mdc-floating-label--float-above) {
  left: 25px;
}

// Floating labels
.mdc-text-field .mdc-floating-label--float-above {
  font-size: 15px !important;
}

// Input boxes in general
.mdc-text-field--outlined .mdc-text-field__input {
  font-size: 15px;
  padding-top: 15px;
  padding-left: 13px;
}

// Input box for TextInputs with a leading icon
.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-text-field__input {
  padding-left: 38px;
}

// Remove vertical padding for textareas
.mdc-text-field--textarea textarea.mdc-text-field__input {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
