@import "src/styles/variables";

.appointments {
  border-top: 1px solid #f0f0f0;
  margin-bottom: 50px;

  .appointmentRow {
    display: grid;
    grid-template-columns: 2fr 4fr 1fr 1fr;
    align-items: center;
    justify-content: center;
    padding: 12px 0;
    border-bottom: 1px solid #f0f0f0;
    font-size: 14px;
    line-height: 1;
    cursor: pointer;

    & > span:first-child {
      justify-self: start;
    }
    & > span:last-child {
      justify-self: end;
    }

    .serviceList {
      padding-right: 2em;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .status {
      font-size: 12px;
      text-transform: uppercase
    }
  }

  .noApptsMessage {
    color: $inactive;
    padding: 20px;
    text-align: center;
  }
}
