@import "src/styles/variables";

.subNav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px 20px 40px;

  ul {
    display: flex;
    align-items: center;
    list-style: none;
    height: 33px;
    li {
      cursor: pointer;
      display: inline-block;
      font-size: 15px;
      font-weight: 500;
      margin-right: 2.5rem;
      padding-bottom: 5px;
      text-transform: uppercase;
      border-bottom: 3px solid transparent;

      &.active {
        color: $secondary;
        border-bottom: 3px solid $secondary;
      }
    }
  }
}
