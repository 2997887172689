@import "src/styles/variables";

.timeBlockForm {
  section {
    display: grid;
    grid-template-columns: [title] 1fr [fields] 3.5fr;
    grid-column-gap: 1em;
    margin: $side-modal-margin;
    padding: 20px 0;
    border-top: 1px solid #DDE2EB;

    .sectionTitle {
      grid-column: title;
      font-size: 15px;
      color: #4B5A73;
      margin-top: 9px;
    }

    .sectionFields {
      grid-column: fields;
      display: flex;
      flex-flow: column nowrap;
    }
  }

  .twoColumns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1em;
  }

  .select:not(:last-child),
  .textInput:not(:last-child),
  .dateTimeInputs:not(:last-child) {
    margin-bottom: 20px;
  }
  :global(.mdc-text-field-helper-line) {
    grid-column: fields;
    margin: -1.2em 0 0.8em;
    * {
      color: #b00020 !important;
    }
  }
  .errorText {
    grid-column: fields;
    font-family: Roboto, sans-serif;
    font-size: 0.75rem;
    margin: -22px 0 0;
    padding: 0.6em 16px 0 16px;
    color: #b00020;
  }
  .errorText:not(:last-child) {
    margin-bottom: 20px;
  }
  .techErrorText {
    margin: 12px 0 5px 5px !important;
    padding: 0;
  }

  .allTechnicians {
    label {
      color: #000;
      font-weight: bold;
    }
  }

  .checkbox {
    align-self: flex-start;
    margin-top: 7px;
  }

  .action {
    margin: $side-modal-margin;
  }

  .submitButton {
    margin-bottom: 40px;
    margin-top: 20px;
  }

  .deleteButton {
    margin-bottom: 20px;

    button {
      color: $secondary;
      margin: 0 auto
    }
  }
}
