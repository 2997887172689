@import "src/styles/variables";

.auth {
  background-color: #f7f8fa;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  h3 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 30px;
    text-align: center;
  }
}

.panel {
  margin-bottom: 1.5rem;
  position: relative;
  display: flex;
  padding: 2rem;
  flex-direction: column;
  min-width: 0;
  width: 420px;
  word-wrap: break-word;

  img {
    margin: 0 auto 30px auto;
    width: 200px
  }
}

.forgot {
  margin-top: 30px;
  text-align: center;
}