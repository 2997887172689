@import "src/styles/variables";

.searchInput {
  align-items: center;
  background-color: $gray;
  border-radius: 20px;
  display: flex;
  padding-left: 15px;
  width: 280px;

  i {
    color: #80868C;
    font-size: 18px;
  }

  input {
    background: transparent;
    border: 0;
    font-size: 15px;
    outline: none;
    padding: 10px 12px;
    width: 100%;
  }

  input::-webkit-search-cancel-button {
    cursor: pointer
  }

  // &:before {
  //   font-family: "Material Icons";
  //   content: "search"
  // }
}