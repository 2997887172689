.technicianFilter {
  margin-top: 50px;
  margin-right: 50px;

  h3 {
    color: #4b5a73;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 15px;
    text-transform: uppercase;
  }

  li {
    margin-bottom: 4px;
  }

  label {
    color: #5f6368
  }
}

.allTechnicians {
  label {
    color: #000;
    font-weight: bold;
  }
}