.overlay {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(51, 51, 51, 0.5);
  overflow-y: scroll;
  z-index: 20;
}

.content {
  display: flex;
  flex-direction: column;
  margin: 95px 0;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 8px 21px 0 rgba(0,0,0,0.26);
  padding: 40px;
  width: 570px;
  z-index: 25;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 29px;
  }

  h3 {
    font-size: 18px;
  }

  .closeButton {
    font-size: 30px;
    line-height: 0;
    cursor: pointer;
  }
}
